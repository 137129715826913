@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .sidebar-icon {
        @apply relative flex items-center justify-center 
               h-12 w-12 mt-2 mb-2 mx-auto shadow-lg
               bg-white text-gray-900
               hover:bg-darkmode-1 hover:text-white
               dark:bg-darkmode-1 dark:text-gray-200
               dark:hover:bg-gray-200 dark:hover:text-darkmode-1
               rounded-2xl
               transition-all duration-300 ease-linear
               cursor-pointer
               mr-4
               ;
    }

    .input-text{
        @apply
        bg-gray-50
            dark:bg-darkmode-2
            dark:bg-opacity-90
            appearance-none
            rounded-xl
            w-60
            py-2 px-4
            text-darkmode-1
            dark:text-gray-200
            leading-tight
            focus:outline-none
            focus:border-black
            font-semibold
            shadow-xl
            uppercase
            dark:border-transparent
            border-2    ;
    }

    .input-button{
        @apply        
            rounded-xl
            text-darkmode-1
            bg-gray-50
            dark:bg-darkmode-2
            dark:text-gray-200
            m-auto
            py-2
            px-2
            w-auto
            shadow-xl
            font-semibold
            tracking-wider
            border-2
            dark:border-transparent
            cursor-pointer
            active:border-black
            ;
    }

    div{
        @apply
            text-black
            bg-white
            dark:text-gray-100
            dark:bg-darkmode-1
            ;
    }

    h1 {
        @apply 
            text-2xl
            font-bold
            p-2
            text-black
            bg-white
            dark:text-white
            dark:bg-darkmode-1
            ;
    }
    p {
        @apply 
            max-w-prose
            text-black
            bg-white
            dark:text-white
            dark:bg-darkmode-1
            ;
    }
    th {
        @apply 
            w-16
            h-16
            font-bold
            text-4xl
            border-2
            font-sans
            ;
    }
    /* tr {
        @apply 
            ;
    } */
    table {
        @apply 
            mx-auto
            my-auto
            table-auto
            max-w-prose
            rounded-2xl
            ;
    }
}